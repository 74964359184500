/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { footerCopy } from '../../copy';
import { trackFooterEvent } from '../../utils/appInsightsCustomEvents';
import theme from '../../utils/theme';

export default function Footer() {
  return (
    <footer className="mt-auto" css={footer} data-cy="footer">
      <div className="container">
        <div className="row justify-content-left">
          <div className="col-12 text-center text-sm-left">
            <div className="row">
              {footerCopy.links.map((link, i) => (
                <div className="col-auto" key={i}>
                  <a
                    href={link.url}
                    aria-label={`${link.text} ${link.ariaText}`}
                    className="mr-4"
                    css={linkStyle}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => trackFooterEvent(link.text)}
                  >
                    {link.text}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div css={body} className="col-12">
            <p css={bodyOne}> {footerCopy.bodyOne}</p>
            <p> {footerCopy.bodyTwo}</p>
            <p> {footerCopy.bodyThree}</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

const footer = css`
  background-color: ${theme.lightGrey};
  padding-bottom: 3.75rem;
  padding-top: 2rem;
`;

const body = css`
  font-weight: 300;
  font-size: 0.875rem;
`;
const bodyOne = css`
  padding-top: 1.9375rem;
`;

const linkStyle = css`
  font-size: 1.25rem;
  color: ${theme.purple};
  font-weight: 500;
  transition: 0.6s;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }
`;
