import { css } from '@emotion/react';
import theme from '../../utils/theme';

export const headerWrapper = css`
  position: -webkit-fixed;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  isolation: isolate;

  width: 100%;
  height: 3.75rem;
  background-color: ${theme.purple};
`;

export const header = css`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: transparent;
  transition: box-shadow 0.6s, background-color 0.6s;

  &.scrolled {
    background-color: white;
    box-shadow: 0 5px 8px -6px ${theme.shadowGrey};
  }

  &.mobile-menu {
    background-color: white;
    border-bottom: 1px solid ${theme.lightGrey};
  }
`;

export const skipToMainContent = css`
  position: absolute;
  top: -200px;
  left: 0;
  right: 0;
  z-index: 1;

  width: fit-content;
  padding: 0.6rem;
  margin: auto;

  color: ${theme.purple};
  background-color: white;
  text-decoration: none;
  border-radius: 0.25rem;

  transition: all 0.6s ease-in-out;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    top: 8px;
    text-decoration: none;
    border: 2px solid ${theme.yellow} !important;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

export const skipToMainContentScrolled = css`
  background-color: ${theme.purple};
  color: white;
`;

export const darkTopBarLinks = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  color: ${theme.white};
`;

export const lightTopBarLinks = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  color: ${theme.purple};
`;

export const link = css`
  line-height: 1.875rem;
  font-size: 15px;
  transition: border-bottom 0.6s;

  a {
    border-radius: 0.375rem;
    height: 1.875rem;
    padding: 0 0.9rem;
    color: #ffffff;
    cursor: inherit;
    font-family: inherit;
    text-decoration: none;

    span {
      box-shadow: none;
      transition: box-shadow 0.6s;
      padding: 0.4rem 0;
    }
  }

  &.disabled {
    color: ${theme.grey600};
    cursor: not-allowed;
  }

  :hover:not(.disabled) {
    cursor: pointer;
    span {
      box-shadow: 0 1px 0 0 ${theme.lightGrey};
    }
  }

  span.active {
    box-shadow: 0 1px 0 0 ${theme.lightGrey};
  }
`;

export const linkDark = css`
  a {
    color: ${theme.purple};

    :focus {
      outline-offset: 2px;
      outline: 1px solid ${theme.purple};
    }

    :hover:not(.disabled) {
      cursor: pointer;
      span {
        box-shadow: 0 1px 0 0 ${theme.purple};
      }
    }
  }
`;

export const linkLight = css`
  a {
    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline-offset: 2px;
      outline: 1px solid ${theme.white};
    }
  }
`;

export const logoDark = css`
  width: 3.94rem;

  transition: opacity 0.6s;

  &:hover {
    opacity: 0.6;
  }

  &:focus {
    border-radius: 0.25rem;
    outline-offset: 6px !important;
    outline: 1px solid ${theme.purple};
  }
`;

export const logoLight = css`
  width: 3.94rem;
  transition: opacity 0.6s;

  &:hover {
    opacity: 0.6;
  }

  &:focus {
    border-radius: 0.25rem;
    outline-offset: 6px !important;
    outline: 1px solid ${theme.white} !important;
  }
`;

export const mobileMenuButtonDark = css`
  all: unset;
  margin-left: 1rem;

  &:focus {
    outline-offset: 2px !important;
    outline: 1px solid ${theme.purple} !important;
  }
`;

export const mobileMenuButtonLight = css`
  all: unset;
  margin-left: 1rem;

  &:focus {
    outline-offset: 2px;
    outline: 1px solid ${theme.white};
  }
`;

export const mobileMenuWrapper = css`
  position: fixed;
  top: 0;
  overflow: hidden;
  isolation: isolate;
  z-index: 20;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  color: ${theme.purple};
  background-color: ${theme.white};
`;

export const mobileMenuHeader = css`
  width: 100%;
  max-width: 100vw;
  height: 3.75rem;

  border-bottom: 1px solid ${theme.lightGrey};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: transparent;
`;

export const mobileMenuItems = css`
  width: 100%;
  padding: 0.9375rem 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  font-weight: 600;
  font-size: 22px;

  a {
    color: inherit;
  }
`;

export const loginButton = css`
  a {
    display: inline-block;
    margin-left: 5px;
    border-radius: 2px;
    background-color: ${theme.yellow};
    padding: 10px 20px;
    transition: background 0.6s ease-in-out, transform 150ms ease;
    color: ${theme.purple};

    :hover {
      color: ${theme.purple};
      text-decoration: none;
      background: ${theme.darkerYellow};
    }
  }
`;
