import React from 'react';

const BurgerMenu = ({ dark }) => (
  <svg
    aria-labelledby="burger-menu-title"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="burger-menu-title">Menu</title>
    <path
      d="M2.25 18.0029H21.75"
      stroke={dark ? '#3C053C' : 'white'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.25 12.0029H21.75"
      stroke={dark ? '#3C053C' : 'white'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.25 6.00293H21.75"
      stroke={dark ? '#3C053C' : 'white'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BurgerMenu;
