import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { isMobile, isTablet } from 'react-device-detect';

class AppInsightsService {
  campaignId = '';
  DEFAULT_CAMPAIGN_ID = '001';
  device;
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.INSTRUMENTATION_KEY,
      disableAjaxTracking: true,
      autoTrackPageVisitTime: true,
      maxBatchInterval: this.getMaxBatchInterval(),
    },
  });

  getMaxBatchInterval() {
    return process.env.MAX_BATCH_INTERVAL
      ? process.env.MAX_BATCH_INTERVAL
      : 15000;
  }

  trackPageView(pageName) {
    this.appInsights.trackPageView({
      name: pageName,
      properties: {
        campaignId: this.campaignId,
        device: this.device,
      },
    });
  }

  trackEvent(eventName) {
    const formattedName = `pulsecard.co.uk: ${eventName}`;
    this.appInsights.trackEvent({
      name: formattedName,
      properties: {
        campaignId: this.campaignId,
        device: this.device,
      },
    });
  }

  defaultCampaignIdIfCampaignIdNotValid(campaignId) {
    const campaignIdIsThreeDigits = campaignId => /^\d{3}$/g.test(campaignId);
    return campaignIdIsThreeDigits(campaignId)
      ? campaignId
      : this.DEFAULT_CAMPAIGN_ID;
  }

  setDevice() {
    if (isMobile) {
      this.device = 'mobile';
    } else if (isTablet) {
      this.device = 'tablet';
    } else {
      this.device = 'desktop';
    }
  }
}

const appInsightsService = new AppInsightsService();

export default appInsightsService;
