import React from 'react';

const CloseX = ({ color }) => (
  <svg
    aria-labelledby="close-icon-title"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="close-icon-title">Close Menu</title>
    <path
      d="M1.5625 18.4368L18.4375 1.56177"
      stroke={color ?? '#3C053C'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.4375 18.4368L1.5625 1.56177"
      stroke={color ?? '#3C053C'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CloseX;
