import appInsightsService from './AppInsights.service';

//header events//
export const trackHeaderMenuClick = label => {
  appInsightsService.trackEvent(`header-${label}-click`);
};

export const trackHeaderLoginClick = () => {
  appInsightsService.trackEvent(`header-Login-click`);
};

//footer event//
export const trackFooterEvent = linkText => {
  appInsightsService.trackEvent(`footer-${linkText}-click`);
};

//index page events//
const trackGetActivatedButton = () => {
  appInsightsService.trackEvent("home-Let's get activated CTA-click");
};

const trackVideoPlay = () => {
  appInsightsService.trackEvent('home-Video started playing');
};

const trackVideoCta = () => {
  appInsightsService.trackEvent('home-Video CTA-click');
};

const trackReadMoreButton = () => {
  appInsightsService.trackEvent('home-Read more CTA-click');
};

const trackFAQsButton = () => {
  appInsightsService.trackEvent('home-View more FAQs CTA-click');
};

export const indexPageEvents = {
  trackGetActivatedButton,
  trackVideoPlay,
  trackVideoCta,
  trackReadMoreButton,
  trackFAQsButton,
};

//Faq page events//
export const trackFAQClick = faqName => {
  appInsightsService.trackEvent(`faqs-${faqName}-click`);
};

//Activate Your Card Page Events//
const trackAppleClick = location => {
  appInsightsService.trackEvent(`activate-${location}-app store-click`);
};

const trackGoogleClick = location => {
  appInsightsService.trackEvent(`activate-${location}-play store-click`);
};

const oamSignInClick = () => {
  appInsightsService.trackEvent(`activate-OAM Sign in-click`);
};

const oamActivateClick = () => {
  appInsightsService.trackEvent(`activate-OAM Activate Card-click`);
};

const trackExploreFaqs = () => {
  appInsightsService.trackEvent(`activate-Explore FAQs-click`);
};

export const activateYourCardPageEvents = {
  trackAppleClick,
  trackGoogleClick,
  oamActivateClick,
  oamSignInClick,
  trackExploreFaqs,
};

// moving to Pulse Page Events

export const trackMovingToPulseCategoryClick = category => {
  appInsightsService.trackEvent(`moving-to-pulse-${category}-click`);
};

export const trackMovingToPulseQuestionClick = question => {
  appInsightsService.trackEvent(`moving-to-pulse-${question}-click`);
};

export const activateMovingToPulsePageEvents = {
  trackMovingToPulseCategoryClick,
  trackMovingToPulseQuestionClick,
};
