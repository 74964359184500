import React from 'react';
import { SharedElementsLayout, StyleLayout } from './src/layout';
import appInsightsService from './src/utils/AppInsights.service';

export const wrapPageElement = ({ element, props }) => {
  return (
    <StyleLayout>
      <SharedElementsLayout path={props.uri}>{element}</SharedElementsLayout>
    </StyleLayout>
  );
};

export const onInitialClientRender = () => {
  appInsightsService.appInsights.loadAppInsights();
  appInsightsService.setDevice();
};
