export default {
  white: '#FFFFFF',
  lightGrey: '#F0F0F0',
  shadowGrey: '#283237',
  orange400: '#E87552',
  orange: '#E66432',
  darkerYellow: '#E59E00',
  yellow: '#F3BD31',
  rewardsYellow: '#FAF01E',
  darkerPurple: '#270428',
  purple: '#3C053C',
  lightPurple: '#AF4B78',
  magenta: '#DC1E46',
  rose: '#F05A82',
  plum: '#82193C',
  black: '#1E1E1E',

  mobile: '576px',
  tablet: '767px',
  laptop: '992px',
  desktop: '1200px',
};
