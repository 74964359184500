import styled from '@emotion/styled';

import AgileBoldItalic from '../../static/fonts/agile/Agile-BoldItalic.ttf';
import AgileBold from '../../static/fonts/agile/Agile-Bold.ttf';
import AgileBook from '../../static/fonts/agile/Agile-Book.ttf';
import AgileBookItalic from '../../static/fonts/agile/Agile-BookItalic.ttf';
import AgileLight from '../../static/fonts/agile/Agile-Light.ttf';
import AgileLightItalic from '../../static/fonts/agile/Agile-LightItalic.ttf';
import AgileMedium from '../../static/fonts/agile/Agile-Medium.ttf';
import AgileMediumItalic from '../../static/fonts/agile/Agile-MediumItalic.ttf';

export const FontWrapper = styled('div')`
  @font-face {
    font-family: 'Agile';
    src: url(${AgileBoldItalic}) format('truetype');
    font-style: italic;
    font-weight: bold;
  }

  @font-face {
    font-family: 'Agile';
    src: url(${AgileBold}) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Agile';
    src: url(${AgileBook}) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Agile';
    src: url(${AgileBookItalic}) format('truetype');
    font-style: italic;
  }

  @font-face {
    font-family: 'Agile';
    src: url(${AgileLight}) format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Agile';
    src: url(${AgileLightItalic}) format('truetype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Agile';
    src: url(${AgileMedium}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Agile';
    src: url(${AgileMediumItalic}) format('truetype');
    font-style: italic;
    font-weight: 400;
  }
`;
