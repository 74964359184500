/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
import creditAgreementPdf from './assets/Pdfs/pulse-credit-agreement.pdf';

export const pulseBenefitsCopy = {
  titleOne: 'Positively Pulsing with benefits',
  benefitsWithImage: [
    {
      subTitle: 'Cashback on your purchases',
      body: 'Earn up to £150 per year in cashback. Get 0.25% cashback every time you shop using your Pulse Mastercard',
    },
    {
      subTitle: 'Spend anywhere',
      body: 'Use it worldwide, wherever you see the Mastercard logo.',
    },
    {
      subTitle: 'Pay with your phone',
      body: 'Easy and safe payments with Google Pay or Apple Pay.',
    },
  ],
  titleTwo: 'Other benefits',
  otherBenefits: [
    'Keep track of your account 24/7, online or via the new Pulse app',
    'Tap and go with your new, contactless card',
    'No annual fees',
  ],
};

export const yourQuestionsCopy = {
  title: 'Your questions answered',
  subTitleOne: 'Will you carry out a credit check that will impact my credit record?',
  bodyOne:
    'No, as you’re an existing NewDay cardholder, we won’t need to run any credit checks before sending you a Pulse card.  That means you will not see another footprint on your credit file.   For more information about what can affect your credit score, click ',
  bodyLink:
    'https://www.experian.com/blogs/ask-experian/credit-education/score-basics/what-affects-your-credit-scores/',
  linkText: 'here',
  subTitleTwo: 'Will my credit limit stay the same?',
  bodyTwoA:
    'Your credit limit under your existing NewDay card will remain the same under your Pulse card, unless we have notified you otherwise.',
  bodyTwoB:
    'If you have more than one NewDay card, please see the letter we sent you introducing Pulse for details of which card we’re inviting you to replace with a Pulse card – it’s the credit limit under that card that will be carried across to Pulse.',
  buttonText: 'View more FAQs',
};

export const indexCopy = {
  metaTitle: 'Welcome to Pulse | Pulse card',
};

export const activateYourCardMetaData = {
  metaTitle: 'Activate Your Card | Pulse card',
};

export const whatYouNeedToDoCopy = {
  p1: `Pulse is by invitation only. Your name's already on the 'approved' list.`,
  p21: `You may have seen our letter`,
  sup1: '1',
  p22: ` about getting and activating your new Pulse card. Please read this carefully, it tells you about important changes to your account. Your interest rate and credit limit will remain the same`,
  sup2: '2',
  p23: ` and you won’t have to apply.`,
};

export const disclaimerCopy = {
  p1: `We’ve sent some important information about your account to your online inbox. Please check app or Online Account Manager (OAM) if you’ve not read it.`,
  p2: `If you have more than one NewDay card, please see the communication we sent you for details of which card we are inviting you to replace with a Pulse card. Pulse will offer the same interest rate and credit limit.`,
};

export const aboutCopy = {
  metaTitle: 'About Us | Pulse card',
  subTitle: 'Pulse is provided by NewDay.',
  paragraphs: [
    `If the name sounds familiar it's because NewDay is one of the UK's largest providers of consumer finance.<br />`,
    `NewDay has a really simple goal – to help people get better with credit.<br />`,
    `Above all, NewDay acts responsibly to help meet its customers' needs, whether it's by offering new ways to access credit or by providing tools that make managing finances easier.<br />`,
    `Credit is provided by NewDay Ltd. NewDay Ltd and NewDay Cards Ltd are companies registered in England and Wales with registered numbers 7297722 and 4134880 respectively and form part of the NewDay group of companies. The registered office for these companies is: 7 Handyside Street, London N1C 4DA. NewDay Ltd and NewDay Cards Ltd are authorised and regulated by the Financial Conduct Authority with numbers 690292 and 682417 respectively. NewDay Ltd is also authorised by the Financial Conduct Authority under the Payment Services Regulations 2017 (Ref no: 555318) for the provision of payment services. Pulse is a trademark of NewDay Cards Ltd, which is used under licence by NewDay Ltd.<br />`,
    `NewDay Ltd is a member of the Finance and Leasing Association (FLA) and it subscribes to the FLA's Lending Code. Copies of the FLA's Lending Code can be obtained from www.fla.org.uk.`,
  ],
};

export const faqsCopy = {
  metaTitle: 'Frequently Asked Questions | Pulse card',
};

export const footerCopy = {
  links: [
    {
      text: 'Privacy Notice',
      url: 'https://www.newday.co.uk/site-services/privacy-policy/',
      ariaText: 'opens in a new tab',
    },
    {
      text: 'Cookie Notice',
      url: 'https://www.newday.co.uk/site-services/cookies-policy/',
      ariaText: 'opens in a new tab',
    },
    {
      text: 'Credit Agreement',
      url: creditAgreementPdf,
      ariaText: 'download pdf document, (521 kilobytes)',
    },
  ],
  bodyOne: 'Credit is provided by NewDay Ltd.',
  bodyTwo:
    'NewDay Ltd and NewDay Cards Ltd are companies registered in England and Wales with registered numbers 7297722 and 4134880 respectively. They form part of the NewDay group of companies. The registered office for these companies is 7 Handyside Street, London, N1C 4DA. NewDay Ltd and NewDay Cards Ltd are authorised and regulated by the Financial Conduct Authority (FCA) with numbers 690292 and 682417 respectively. NewDay Ltd is also authorised by the FCA under the Payment Services Regulations 2017 (ref no: 555318) for the provision of payment services. Pulse is a trademark of NewDay Cards Ltd, which is used under licence by NewDay Ltd.',
  bodyThree: '© NewDay Ltd. 2021',
};

export const faqsOptionsCopy = [
  {
    id: 'moving-to-pulse',
    text: 'Moving to Pulse',
    imgName: 'sync',
    alt: 'card synchronizing',
  },
  {
    id: 'pulse-card',
    text: 'Cashback',
    imgName: 'cashback',
    alt: 'cashback',
  },
  {
    id: 'contact-us',
    text: 'Contact Us',
    imgName: 'contact',
    alt: 'phone ringing',
  },
  {
    id: 'balance-statements',
    text: 'Balance & Statements',
    imgName: 'balance',
    alt: 'Balance & Statements',
  },
  {
    id: 'credit-limit-score',
    text: 'Credit Limit & Score',
    imgName: 'limitScore',
    alt: 'Credit Limit & Score',
  },
  {
    id: 'interest-charges',
    text: 'Interest, Fees & Charges',
    imgName: 'interestCharges',
    alt: 'Interest, Fees & Charges',
  },
  {
    id: 'managing-card',
    text: 'Managing My Card',
    imgName: 'managingCard',
    alt: 'Managing My Card',
  },
  {
    id: 'promotions',
    text: 'Offers & Promotions',
    imgName: 'promotions',
    alt: 'Offers & Promotions',
  },
  {
    id: 'other-information',
    text: 'Other Useful Information',
    imgName: 'otherInfo',
    alt: 'Other Useful Information',
  },
  {
    id: 'payment-difficulties',
    text: 'Payment Difficulties',
    imgName: 'paymentDifficulties',
    alt: 'Payment Difficulties',
  },
  {
    id: 'payments-transactions',
    text: 'Payments & Transactions',
    imgName: 'paymentsTransactions',
    alt: 'Payments & Transactions',
  },
  {
    id: 'security-fraud',
    text: 'Security & Fraud',
    imgName: 'securityFraud',
    alt: 'Security & Fraud',
  },
  {
    id: 'online-account',
    text: 'Your Online Account',
    imgName: 'onlineAccount',
    alt: 'Online Account',
  },
  {
    id: 'trusted-retailers',
    text: 'Trusted Retailers',
    imgName: 'trustedRetailers',
    alt: 'Trusted Retailers',
  },
];

export const MovingToPulseMetaData = {
  metaTitle: 'Moving to Pulse | Pulse card',
};

export const MovingToPulseCopy = [
  {
    id: 'what-is-pulse',
    name: 'What Is Pulse?',
    href: '/moving-to-pulse/what-is-pulse',
    questions: [
      {
        id: 'what-is-a-pulse-card',
        expanded: true,
        title: 'What is a Pulse card?',
        content: [
          'A Pulse card is a new cashback Mastercard®  that replaces your existing NewDay card. Pulse is offered by invitation only. We’ve sent some important information about your account to your online inbox. Please check your app or <a href="https://portal.newdaycards.com/pulse/login" target="_blank" aria-label=“portal.newdaycards.com/pulse/login Opens in a new tab">Online Account Manager</a>. This will explain some changes we’re making to your account and what to do if you don’t want a Pulse card.',
        ],
      },
      {
        id: 'pulse-cashback-rate',

        title: 'What is the Pulse cashback rate?',
        content: [
          'You can earn up to £150 per year in cashback at a rate of 0.25% on all qualifying purchases.',
        ],
      },
      {
        id: 'where-to-use-pulse-card',

        title: 'Where can I use my Pulse credit card?',
        content: [
          "Your Pulse credit card is accepted wherever the Mastercard logo is displayed – that's at over 36 million outlets worldwide.",
        ],
      },
      {
        id: 'existing-balance',

        title: 'What happens to my existing balance?',
        content: [
          'Your existing balance will automatically transfer to your new Pulse account. You’re still responsible for making at least your contractual minimum payment each month until your balance is cleared (even if you don’t have a Pulse card).',
        ],
      },
      {
        id: 'credit-record',

        title: 'Will you carry out a credit check that will impact my credit record?',
        content: [
          'No, as you’re an existing NewDay cardholder, we won’t need to run any credit checks before moving your account to Pulse. That means you won’t see another footprint on your credit file.',
        ],
      },
      {
        id: 'interest-rate-and-charges',

        title: 'Will my interest rate and charges stay the same?',
        content: [
          'Yes, your interest rate as well as any rates and charges under your existing NewDay card will be carried across to your Pulse account.',
        ],
      },
      {
        id: 'credit-limit',

        title: 'Will my credit limit stay the same?',
        content: ['Yes, unless we’ve notified you otherwise.'],
      },
      {
        id: 'online-account',

        title: 'Will I still be able to manage my account online or via the app?',
        content: [
          'If you manage your existing NewDay card through the <a href="https://portal.newdaycards.com/pulse/login" target="_blank" aria-label=“portal.newdaycards.com/pulse/login Opens in a new tab">Online Account Manager</a>, you can continue to do so. Alternatively, if you use the app, you’ll need to download the Pulse card app once your account switches over. Your switch date is the date specified in information we’ve recently sent you. You should be able to use your existing username to sign in to the new Pulse card app and <a href="https://portal.newdaycards.com/pulse/login" target="_blank" aria-label=“portal.newdaycards.com/pulse/login Opens in a new tab">Online Account Manager</a>. A small number of customers may need to re-register. If that’s the case, we’ll get in touch with instructions on how to do so.',
        ],
      },
    ],
  },
  {
    id: 'managing-your-account',
    name: 'Managing your account',
    href: '/moving-to-pulse/managing-your-account',
    questions: [
      {
        id: 'online-account-manager',
        expanded: true,
        title: 'How do I register for the Online Account Manager?',
        content: [
          "Visit our <a href='https://portal.newdaycards.com/pulse/register' target='_blank' aria-label=portal.newdaycards.com/pulse/register Opens in a new tab'>registration page</a> and follow the on-screen instructions. You'll need your card or account number to hand. You can find your account number in your welcome communications, as well as on your monthly statement.",
        ],
      },
      {
        id: 'login-username',

        title:
          'What username do I use to log in to the new Pulse card app and Online Account Manager?',
        content: [
          'You should be able to use your existing username to sign in to the new Pulse card app and <a href="https://portal.newdaycards.com/pulse/login" target="_blank" aria-label=“portal.newdaycards.com/pulse/login Opens in a new tab">Online Account Manager</a>. A small number of customers may need to re-register. If that’s the case, we’ll get in touch with instructions on how to do so.',
        ],
      },
      {
        id: 'existing-account-details',

        title: 'How do I view my existing account details, credit limit or interest rates?',
        content: [
          'You can view your existing account details, credit limit and how much credit you have available on the first page of your statement under your account summary. If you’re signed up for eStatements, you can view them through the <a href="https://portal.newdaycards.com/pulse/login" target="_blank" aria-label=“portal.newdaycards.com/pulse/login Opens in a new tab">Online Account Manager</a> or the Pulse card app.',
        ],
      },
      {
        id: 'pulse-account-number',

        title: 'Where can I find my Pulse account number?',
        content: [
          'Your account number remains the same. You can find your account number in your welcome communications, as well as on your monthly statement.',
        ],
      },
      {
        id: 'additional-card-holders',

        title: 'Can I add additional card holders?',
        content: ['Yes, you can add up to three additional cardholder to your account.'],
      },
      {
        id: 'manage-card-holders',

        title: 'How do I manage my card holders?',
        content: [
          'To manage your card holders please visit the ‘Additional card holders’ section of the Pulse card app or <a href="https://portal.newdaycards.com/pulse/login" target="_blank" aria-label=“portal.newdaycards.com/pulse/login Opens in a new tab">Online Account Manager</a>.',
        ],
      },
      {
        id: 'cannot-currently-spend',

        title: 'What happens if I cannot currently spend on my account?',
        content: [
          'As you’re currently unable to use your account to make purchases, you won’t receive a new card when your account is moved to Pulse. If your circumstances change, please get in touch with us by visiting the contact us option in the <a href="https://www.pulsecard.co.uk/frequently-asked-questions/" target="_blank" aria-label=“pulsecard.co.uk/frequently-asked-questions Opens in a new tab">FAQ</a>.',
        ],
      },
    ],
  },
  {
    id: 'paying-back-your-balance',
    name: 'Paying back your balance',
    href: '/moving-to-pulse/paying-back-your-balance',
    questions: [
      {
        id: 'make-a-payment',
        expanded: true,
        title: 'How can I make a payment?',
        content: [
          'If you pay by Direct Debit, your existing payments will remain the same – you don’t need to take any action. There are other ways to pay including:',
          `<ul>
            <li>Pulse card app</li>
            <li>Online Account Manager</li>
            <li>Internet Banking</li>
            </ul>`,
          'Take a look at the payments screen in your Pulse card app or <a href="https://portal.newdaycards.com/pulse/login" target="_blank" aria-label=“portal.newdaycards.com/pulse/login Opens in a new tab">Online Account Manager</a> for more information.',
        ],
      },
      {
        id: 'repeat-payments',
        title: 'What happens to my repeat payments/recurring transactions?',
        content: [
          'If you receive and activate a Pulse card, your Pulse card number will be the same as your previous card (with a different expiry date and security number). You should therefore check whether the payment reference for any repeat transactions/recurring payments (such as magazine subscriptions and insurance) that you have set up using your card contain your expiry date and security number. If they do, you‘ll need to update these.',
          'If you’re not eligible for a Pulse card or don’t wish to receive one, we’ll close your account with us on the date we’ve communicated to you. This means that any regular payments/recurring transactions made using your card will stop from the date we close your account. This means you may need to make alternative payment arrangements with the relevant suppliers.',
        ],
      },
      {
        id: 'payment-limit',
        title: 'How much can I pay each month?',
        content: [
          'You can pay whatever you like each month, as long as you make at least the contractual minimum payment. If you only make the contractual minimum payment, this will cost you more and it will take longer to clear your balance.',
        ],
      },
      {
        id: 'change-date',
        title: 'Can I change the date I pay each month?',
        content: [
          "Yes, you can change the date when your payments are due up to three times per year - it's one of the benefits of being a Pulse customer. To find out more, please visit 'Change My Due Date' under the 'Payments and Transfers’ section in your mobile app or <a href='https://portal.newdaycards.com/pulse/login' target='_blank' aria-label='portal.newdaycards.com/pulse/login Opens in a new tab'>Online Account Manager</a>.",
        ],
      },
    ],
  },
  {
    id: 'multiple-accounts',
    name: 'Multiple accounts',
    href: '/moving-to-pulse/multiple-accounts',
    questions: [
      {
        id: 'more-than-one-account',
        expanded: true,
        title: 'Why do I have more than one Pulse account?',
        content: [
          'If the relationship between and NewDay and one of our retailers has come to an end, we may move that account to Pulse. If a customer has accounts with more than one retailer where the programme has ended, then all accounts will move to Pulse.',
        ],
      },
      {
        id: 'two-active-cards',
        title: 'Can I have two active Pulse cards?',
        content: [
          'You can only have one active Pulse card. Other accounts that carry a balance will need to be paid down in line with your agreement with NewDay.',
        ],
      },
      {
        id: 'mora-than-one-pulse-account',
        title: 'What do I need to do If I have more than one Pulse account?',
        content: [
          'You can manage all your accounts online using your <a href="https://portal.newdaycards.com/pulse/login" target="_blank" aria-label=“portal.newdaycards.com/pulse/login Opens in a new tab">Online Account Manager</a>, or by downloading the Pulse card app. Each account will have a separate login which should be the same as you use today. We’ll write to you separately if you need to re-register.',
        ],
      },
      {
        id: 'manage-my-login',
        title: 'How do I manage my app login on my mobile phone?',
        content: [
          'Managing more than one account on your app may mean you need to reset your phone’s biometric security each time you switch to another account. It might be easier for you to have your active Pulse account on your mobile phone and manage any other accounts through your <a href="https://portal.newdaycards.com/pulse/login" target="_blank" aria-label=“portal.newdaycards.com/pulse/login Opens in a new tab">Online Account Manager</a>.',
        ],
      },
      {
        id: 'different-payment-dates',
        title: 'What if I have different payment dates for each Pulse account?',
        content: [
          'If you have differing payment dates, you can either continue with these separate payment dates, or alter each of your payment dates in the ‘managing payments’ section of your <a href="https://portal.newdaycards.com/pulse/login" target="_blank" aria-label=“portal.newdaycards.com/pulse/login Opens in a new tab">Online Account Manager</a> or Pulse card app.',
        ],
      },
      {
        id: 'transfer-balances',
        title: 'Can I transfer the balances onto one card?',
        content: [
          'No, unfortunately it’s not possible to transfer your balance onto a single account. You’ll need to manage each account separately.',
        ],
      },
    ],
  },
  {
    id: 'cashback',
    name: 'Cashback',
    href: '/moving-to-pulse/cashback',
    questions: [
      {
        id: 'cashback-rate',
        expanded: true,
        title: 'What is the Pulse cashback rate?',
        content: [
          'You can earn up to £150 per year in cashback at a rate of 0.25% on all qualifying purchases.',
        ],
      },
      {
        id: 'earn-cashback',
        title: 'What can I earn cashback on?',
        content: [
          'You’ll earn cashback every time you spend using your Pulse card up to £150 in a 12-month period. Certain transactions are excluded from earning cashback including',
          `<ul>
            <li>Money transfers</li>
            <li>Balance transfers</li>
            <li>Buy Now Pay Later</li>
            <li>Travellers’ cheques</li>
            <li>Travel money</li>
            <li>Foreign currency</li>
            </ul>`,
          'For a full list of exclusions please visit the cashback section in our main FAQs page',
        ],
      },
      {
        id: 'cashback-payment',
        title: 'How and when is my Pulse cashback paid?',
        content: [
          'Cashback will usually be credited to your Pulse account within 35 days (although it can sometimes take up to 60 days). Your statement will show the amount of cashback earned on the purchases shown on that statement.',
        ],
      },
      {
        id: 'cancel-purchase',
        title: 'What happens if I cancel or return a purchase on which I earned cashback?',
        content: [
          'If you cancel or return a purchase made using your Pulse card on which you’ve earned cashback, then either that cashback will not be credited to your account, or if it’s already been credited, it’ll be deducted.',
        ],
      },
      {
        id: 'cashback-eligibility',
        title: 'Does how I manage my Pulse account affect my eligibility for cashback?',
        content: [
          'Yes, in order to qualify for cashback, you must make at least your contractual minimum payment on time each month and stay within your credit limit. Failure to do so will mean that any cashback earned since your last statement date will be lost.',
        ],
      },
      {
        id: 'additional-card-holders-cashback',
        title: 'Will my additional Pulse cardholder(s) also receive cashback?',
        content: [
          'Yes, purchases made by additional cardholders using their Pulse card are eligible for cashback.',
          'Together, you can earn a combined total of up to £150 cashback a year (ie a maximum of £150 cashback will be credited to the account in any given year, regardless of how many additional cardholders you have).',
        ],
      },
      {
        id: 'close-account',
        title: 'Will I lose any cashback I’ve earned if I close my Pulse account?',
        content: [
          'If you tell us you want to close your account, any purchases you make using your Pulse card won’t be eligible for cashback from that point onwards. Also, cashback already earned won’t be credited to your account.',
        ],
      },
    ],
  },
];
